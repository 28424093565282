import * as logger from "@/tools/logger.js";
import * as statusMapper from "@/service/error_request_mapper.js";

const _FILE_PATH = "service/sfr/employee_title_service.js";
const _M_GET_ALL = "getAllEmployeesTitle";
const _M_GET_BY_ID = "getEmployeeTitleById";

/** Service d'accès à l'API des titres employées */
export default class EmployeeTtileService {
  constructor(apis) {
    this.apis = apis;
    this.api = this.apis.getEmployeesTitleApi();
  }

  /////////////////////////////// GET /////////////////////////////////////////
  /**
   * Récupère la liste des titres employés et les tri par ordre alphabétique
   */
  async getAllEmployeesTitle() {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_ALL,
      "Récupération de tous les titres employées."
    );

    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });

        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Récupère un titre employé par son id
   */
  async getEmployeeTitleById(id) {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_BY_ID,
      "Récupération du titre employé avec l'id : " + id
    );

    return this.api.getById(id).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /////////////////////////////// CREATE /////////////////////////////////////////

  async create(item) {
    return this.api.create(item).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(item.id);
      converter.add409AlreadyExists(item.name);
      converter.convert(error);
    });
  }

  /////////////////////////////// UPDATE /////////////////////////////////////////

  async update(item) {
    return this.api.update(item).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(item.id);
      converter.convert(error);
    });
  }

  /////////////////////////////// DELETE /////////////////////////////////////////

  async delete(itemId) {
    return this.api.delete(itemId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(itemId);
      converter.convert(error);
    });
  }
}
